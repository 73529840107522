import cn from 'classnames';
import dayjs from 'dayjs';
import TZ from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';
import Link from 'next/link';
import { FC, UIEventHandler, useEffect, useMemo, useRef, useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import useOnScreen from '~utils/hooks/useOnScreen';

import s from './DateSelector.module.css';

dayjs.extend(UTC);
dayjs.extend(TZ);

interface Props {
  dates: string[];
  date: string;
  timezone: string;
  ignoreToday?: boolean;
  className?: string;
  buttonClassname?: string;
  disableInitialScroll?: boolean;
  onChange?: (date: string) => void;
  getHref?: (date: string) => string;
}

const DateSelector: FC<Props> = ({
  dates,
  date,
  timezone,
  ignoreToday,
  className = '',
  buttonClassname,
  disableInitialScroll,
  onChange,
  getHref,
}) => {
  const today = dayjs().tz(timezone);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRefIsVisible = useOnScreen(scrollRef);
  const [width, setWidth] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [leftMax, setLeftMax] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);

  const visibleLeft = left && left > 0;
  const visibleRight = width < scrollWidth && left < leftMax;

  const datesToShow = useMemo(() => {
    if (!dates.length) return [];

    if (today.isSame(dayjs(dates[0]).tz(timezone), 'day')) {
      return dates;
    }

    if (today.isAfter(dates[0], 'day')) {
      return dates.filter((item) => {
        const itemDate = dayjs(item).tz(timezone);
        return itemDate.isAfter(today, 'day') || itemDate.isSame(today, 'day');
      });
    }
    if (today.isBefore(dates[0], 'day') && !ignoreToday) {
      return [today.format(), ...dates];
    }
    return dates;
  }, [dates]);

  useEffect(() => {
    if (scrollRef.current && (!width || !scrollWidth)) {
      setWidth(scrollRef.current.offsetWidth);
      setScrollWidth(scrollRef.current.scrollWidth);
      setLeftMax(scrollRef.current.scrollWidth - scrollRef.current.offsetWidth);
    }
  }, [scrollRef.current]);

  const itemWidth = () => {
    return scrollRef?.current?.firstElementChild
      ? parseFloat(getComputedStyle(scrollRef.current.firstElementChild).width)
      : 0;
  };

  const pageWidth = () => {
    return itemWidth() * (width / itemWidth() || 0);
  };

  const moveToLeft = () => {
    scrollRef?.current?.scrollTo?.({ left: left - pageWidth(), behavior: 'smooth' });
  };

  const moveToRight = () => {
    const page = pageWidth();
    //const newLeft = left + pageWidth();
    const newLeft = left + page < leftMax ? left + page : leftMax;
    scrollRef?.current?.scrollTo?.({
      left: newLeft,
      behavior: 'smooth',
    });
    setLeft(newLeft);
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLeft(e?.currentTarget?.scrollLeft);
  };

  useEffect(() => {
    if (scrollRefIsVisible && typeof document !== 'undefined' && !disableInitialScroll) {
      document
        .getElementById('selectedDate')
        ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [dates, scrollRefIsVisible, disableInitialScroll]);

  return (
    <div className={s.root}>
      <div className={s.scroll} onScroll={handleScroll} ref={scrollRef}>
        <div className={cn(s.dates, className)}>
          {!!datesToShow?.length &&
            datesToShow.map((item, index) => {
              const month = dayjs(item).tz(timezone).format('MMM DD');
              const day = dayjs(item).tz(timezone).format('ddd');
              return (
                <Link
                  data-testid="show-date"
                  prefetch={false}
                  href={getHref ? getHref(item) : '#'}
                  key={index}
                  className={cn(s.date, {
                    [s.dateSelected]: dayjs(date).tz(timezone).isSame(item, 'day'),
                  })}
                  {...(dayjs(date).tz(timezone).isSame(item, 'day') ? { id: 'selectedDate' } : {})}
                  onClick={(e) => {
                    if (onChange) {
                      e.preventDefault();
                      e.stopPropagation();
                      onChange?.(dayjs(item).tz(timezone).format());
                    }
                  }}
                >
                  <span className={s.month}>
                    {`${month.charAt(0).toUpperCase()}${month.slice(1)}`}
                  </span>
                  <span className={s.day}>{`${day.charAt(0).toUpperCase()}${day.slice(1)}`}</span>
                </Link>
              );
            })}
        </div>
      </div>
      <button
        className={cn(s.button, s.leftButton, buttonClassname, { [s.activeButton]: visibleLeft })}
        onClick={moveToLeft}
      >
        <LeftOutlined />
      </button>
      <button
        className={cn(s.button, s.rightButton, buttonClassname, { [s.activeButton]: visibleRight })}
        onClick={moveToRight}
      >
        <RightOutlined />
      </button>
    </div>
  );
};

export default DateSelector;
