// noinspection SpellCheckingInspection
import cn from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import { FC, MouseEventHandler, UIEventHandler, useEffect, useRef, useState } from 'react';

import { LeftOutlined, PlayCircleFilled, RightOutlined } from '@ant-design/icons';

import Poster from '~components/ui/Poster';
import go from '~utils/go';
import { NormalizedComingSoon } from '~utils/normalizers/normalizeComingSoon';

import s from './MoviesCarousel.module.css';

// TODO: Obtener el dato del cine
const tz = 'America/Mazatlan';

interface Props {
  movies: NormalizedComingSoon[];
}

const MoviesCarousel: FC<Props> = ({ movies }) => {
  const type = (() => {
    switch (movies.length) {
      case 5:
        return 'quintet';
      case 4:
        return 'quartet';
      case 3:
        return 'trio';
      case 2:
        return 'duo';
      case 1:
        return 'unique';
      default:
        return 'carousel';
    }
  })();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [leftMax, setLeftMax] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);

  const visibleLeft = left && left > 0;
  const visibleRight = width < scrollWidth && left < leftMax;

  useEffect(() => {
    if (scrollRef.current && (!width || !scrollWidth)) {
      setWidth(scrollRef.current.offsetWidth);
      setScrollWidth(scrollRef.current.scrollWidth);
      setLeftMax(scrollRef.current.scrollWidth - scrollRef.current.offsetWidth);
    }
  }, [scrollRef.current]);

  const itemWidth = () => {
    return scrollRef?.current?.firstElementChild
      ? parseFloat(getComputedStyle(scrollRef.current.firstElementChild).width)
      : 0;
  };

  const pageWidth = () => {
    return itemWidth() * (width / itemWidth() || 0);
  };

  const moveToLeft = () => {
    scrollRef?.current?.scrollTo?.({ left: left - pageWidth(), behavior: 'smooth' });
  };

  const moveToRight = () => {
    const page = pageWidth();
    const newLeft = left + page < leftMax ? left + page : leftMax;
    scrollRef?.current?.scrollTo?.({
      left: newLeft,
      behavior: 'smooth',
    });
    setLeft(newLeft);
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLeft(e?.currentTarget?.scrollLeft);
  };

  const openTrailer =
    (movie: NormalizedComingSoon): MouseEventHandler<HTMLButtonElement> =>
    (e): void => {
      e.stopPropagation();
      e.preventDefault();
      go(`/pelicula/${movie.slug}#trailer`);
    };

  const sizes = {
    carousel: '200px',
    unique:
      '(min-width: 1280px) 200vw,(min-width: 1024px) 150px, (min-width: 768px) 100px,' +
      ' (min-width: 640px) 100px, 300px',
    duo: '(min-width: 1280px) 200px,(min-width: 1024px) 150px, (min-width: 768px) 100px,200px',
    trio: '(min-width: 1280px) 360vw,(min-width: 1024px) 270px, 200px',
    quartet: '(min-width: 1280px) 270vw,(min-width: 1024px) 200px, (min-width: 768px) 150px, 200px',
    quintet: '(min-width: 1280px) 200vw,(min-width: 1024px) 160px, 200px',
  };

  return (
    <div className={cn(s.root, s[type])}>
      <div className={s.wrapper} ref={scrollRef} onScroll={handleScroll}>
        {movies.map((movie) => (
          <Link href={`/pelicula/${movie.slug}`} key={movie.id} className={s.movie}>
            {movie?.poster && (
              <Poster data={movie?.poster} className={s.poster} sizes={sizes[type]} />
            )}
            <div className={s.content}>
              <div>
                <h4 className={s.title}>{movie.title}</h4>
                <span className={s.originalTitle}>{movie.originalTitle}</span>
              </div>
              {movie?.CinemaMovies?.[0]?.previewAt ? (
                <span className={s.date}>
                  Pre-estreno:{' '}
                  {dayjs(movie.CinemaMovies[0].previewAt)
                    .tz(tz)
                    .format('DD - MMM -' + ' YY')}
                </span>
              ) : (
                <span className={s.date}>
                  Estreno:{' '}
                  {dayjs(movie?.CinemaMovies?.[0]?.premiereAt)
                    .tz(tz)
                    .format('DD - MMM - YY')}
                </span>
              )}
              {['unique', 'duo'].includes(type) && (
                <div className={s.buttons}>
                  <button className="btn btn-primary xl:btn-lg">Más información</button>
                  <button className="btn btn-dark xl:btn-lg" onClick={openTrailer(movie)}>
                    <PlayCircleFilled />
                  </button>
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
      <button className={cn(s.arrowBtn, { [s.buttonLeft]: visibleLeft })} onClick={moveToLeft}>
        <LeftOutlined />
      </button>
      <button className={cn(s.arrowBtn, { [s.buttonRight]: visibleRight })} onClick={moveToRight}>
        <RightOutlined />
      </button>
    </div>
  );
};

export default MoviesCarousel;
