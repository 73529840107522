import classNames from 'classnames';
import { HTMLProps, LegacyRef, forwardRef } from 'react';

import s from './Select.module.css';

interface Props extends HTMLProps<HTMLSelectElement> {
  type?: 'primary' | 'default';
}

const Select = (
  { type = 'default', className, ...rest }: Props,
  ref: LegacyRef<HTMLSelectElement>,
) => {
  return <select className={classNames(s.control, s[type], className)} {...rest} ref={ref} />;
};

export default forwardRef(Select);
