import cn from 'classnames';
import dayjs from 'dayjs';
import TZ from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';
import Link from 'next/link';
import { FC } from 'react';

import { PlayCircleFilled } from '@ant-design/icons';

import Poster from '~components/ui/Poster';
import useGlobalState from '~utils/hooks/useGlobalState';
import { NormalizedComingSoon } from '~utils/normalizers/normalizeComingSoon';

import { Cinema } from '~types';

import PresaleCountDown from '../PresaleCountDown';
import s from './PresaleMovie.module.css';

dayjs.extend(UTC);
dayjs.extend(TZ);

interface Props {
  data: NormalizedComingSoon;
}

const PresaleMovie: FC<Props> = ({ data }) => {
  const [cinemas] = useGlobalState<Cinema[]>('/cinemas/list');
  const cinemaMovie = data?.CinemaMovies?.find((movieData) => movieData.enablePresale);
  const cinema = cinemas?.find?.((item) => item.id === cinemaMovie?.CinemaId);
  const tz = cinema?.timezone || 'America/Mazatlan';
  const isAfterPremiere = dayjs().isAfter(dayjs(cinemaMovie?.premiereAt));
  const isAfterPreview = cinemaMovie?.previewAt
    ? dayjs().isAfter(dayjs(cinemaMovie?.previewAt))
    : true;
  const isAfterPresaleStart = dayjs().isAfter(dayjs(cinemaMovie?.presaleStartAt));
  const onPresale =
    dayjs().isAfter(dayjs(cinemaMovie?.presaleStartAt).startOf('day')) &&
    dayjs().isBefore(dayjs(cinemaMovie?.premiereAt).endOf('day'));
  const parsedSynopsis = data?.synopsis ?? '';

  return (
    <div className={cn(s.root, { [s.onPresale]: onPresale })}>
      <Link href={`/pelicula/${data.slug}`} className="relative block">
        {data?.poster && (
          <Poster
            data={data?.poster}
            version="url"
            sizes="(min-width: 1280px) 320,(min-width: 1024px) 288px, (min-width: 640px) 213px, 320px"
          />
        )}
        {onPresale && (
          <div className={s.presaleTagContainer}>
            <span className={s.presaleTag}>En Preventa</span>
          </div>
        )}
      </Link>
      <div className={s.info}>
        <div>
          <h3 className={s.title}>
            <Link href={`/pelicula/${data.slug}`}>{data?.title}</Link>
          </h3>
          {data?.originalTitle && <span className="quote">{data?.originalTitle}</span>}
        </div>
        <div className="flex w-full flex-col items-center sm:max-w-[274px]">
          {!!cinemaMovie?.previewAt && !isAfterPreview && isAfterPresaleStart && (
            <PresaleCountDown date={cinemaMovie?.previewAt} type="preview" />
          )}
          {isAfterPreview && cinemaMovie?.premiereAt && !isAfterPremiere && isAfterPresaleStart && (
            <PresaleCountDown date={cinemaMovie?.premiereAt} type="premiere" />
          )}
          {cinemaMovie?.presaleStartAt && !isAfterPresaleStart && (
            <PresaleCountDown date={cinemaMovie?.presaleStartAt} type="presale" />
          )}
          <span className={s.premierDate}>
            {cinemaMovie?.previewAt ? (
              <>
                Preestreno:{' '}
                {dayjs(cinemaMovie?.previewAt)
                  .tz(tz)
                  .format('DD [de] MMMM [del] YYYY')}
              </>
            ) : (
              <>
                Estreno:{' '}
                {dayjs(cinemaMovie?.premiereAt)
                  .tz(tz)
                  .format('DD [de] MMMM [del] YYYY')}
              </>
            )}
          </span>
        </div>
        {data?.synopsis && (
          <div className={s.synopsis} dangerouslySetInnerHTML={{ __html: parsedSynopsis }} />
        )}

        <div className={s.buttons}>
          {onPresale ? (
            <Link href={`/pelicula/${data?.slug}#horarios`} className="btn btn-primary lg:btn-xl">
              Compra tus entradas
            </Link>
          ) : (
            <Link href={`/pelicula/${data?.slug}#detalles`} className="btn btn-primary lg:btn-xl">
              Más información
            </Link>
          )}
          <Link href={`/pelicula/${data?.slug}#trailer`} className="btn btn-dark px-6 lg:btn-xl">
            <PlayCircleFilled />
            <span>Ver trailer</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default PresaleMovie;
