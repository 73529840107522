const secondsInMinute = 60;
const secondsInHour = secondsInMinute * 60;
const secondsInDay = secondsInHour * 24;

const parseDuration = (time: number) => {
  let n = time;
  const days = Math.floor(n / secondsInDay);
  n = n % secondsInDay;
  const hours = Math.floor(n / secondsInHour);

  n %= secondsInHour;
  const minutes = Math.floor(n / secondsInMinute);

  n %= secondsInMinute;
  const seconds = n;
  return { days, hours, minutes, seconds };
};

export default parseDuration;
