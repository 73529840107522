import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { FC, useEffect, useState } from 'react';

import parseDuration from '~utils/parseDuration';

import s from './PresaleCountDown.module.css';

dayjs.extend(duration);

interface Props {
  date: string;
  type: 'premiere' | 'presale' | 'preview';
  size?: 'small' | 'normal';
}

const PresaleCountDown: FC<Props> = ({ date, type, size }) => {
  // Segundos faltantes
  const [duration, setDuration] = useState<ReturnType<typeof parseDuration>>();

  useEffect(() => {
    setTimeout(() => {
      setDuration(parseDuration(dayjs(date).diff(dayjs(), 'seconds')));
    }, 1000);
  }, [duration]);

  return (
    <div className={s.root}>
      <span className={`${!!size ? (size === 'small' ? s.smallTitle : s.title) : s.title}`}>
        {type === 'presale' && 'Preventa inicia en:'}
        {type === 'premiere' && 'Estreno en:'}
        {type === 'preview' && 'Preestreno en:'}
      </span>
      {!!duration && (
        <div className={s.time}>
          <span>
            <span className={`${!!size ? (size === 'small' ? s.smallValue : s.value) : s.value}`}>
              {`${duration?.days}`.padStart(2, '0')}:
            </span>
            <span className={s.label}>Días</span>
          </span>
          <span>
            <span className={`${!!size ? (size === 'small' ? s.smallValue : s.value) : s.value}`}>
              {`${duration?.hours}`.padStart(2, '0')}:
            </span>
            <span className={s.label}>Horas</span>
          </span>
          <span>
            <span className={`${!!size ? (size === 'small' ? s.smallValue : s.value) : s.value}`}>
              {`${duration?.minutes}`.padStart(2, '0')}:
            </span>
            <span className={s.label}>Mins.</span>
          </span>
          <span>
            <span className={`${!!size ? (size === 'small' ? s.smallValue : s.value) : s.value}`}>
              {`${duration?.seconds}`.padStart(2, '0')}
            </span>
            <span className={`${s.label} !pr-[0]`}>Segs.</span>
          </span>
        </div>
      )}
    </div>
  );
};

export default PresaleCountDown;
